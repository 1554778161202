import React from "react";
import styled from "styled-components";
import { H4 } from "../typography";
import breakpoints from "../../utils/breakpoints";

const Container = styled.div`
  padding: 18.5rem var(--grid-padding) 0;
  margin-bottom: 1.25rem;
  color: #224466;

  @media ${breakpoints.tabletSm} {
    margin-bottom: 5rem;
    text-align: center;
  }
`;

export default function HeroLegal({ title }) {
  return (
    <Container>
      <H4 as="h1">{title}</H4>
    </Container>
  );
}
