import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import HeroLegal from "../components/hero-legal";
import LegalNotice from "../components/legal-notice";

export default function Legal({ location, path, data }) {
  const { entry, seo, search, team, portfolio_companies, nav, footer_nav, globalSet } = data.cms;
  const { sections, title } = entry;
  return (
    <Layout theme="light" location={location} nav={nav} footer_nav={footer_nav} global={globalSet} search={search} seo={seo} team={team} portfolio_companies={portfolio_companies} >
      <Seo
        title={seo.og_title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-image-1.jpg"
          }
        ]}
      />

      <HeroLegal title={title} />
      <LegalNotice sections={sections} />
    </Layout>
  );
}
export const query = graphql`
  query PageLegal($pageId: String, $siteHandle: String) {
    cms {
      nav(handle: "main_nav") {
        tree(site: $siteHandle) {
          page {
            title
            url
            ... on cms_NavPage_MainNav {
              external
            }
          }
          children {
            page {
              title
              url
            }
          }
        }
      }
      footer_nav: nav(handle: "footer_nav") {
        tree(site: $siteHandle) {
          page {
            title
            url
          }
          children {
            page {
              title
              url
            }
          }
        }
      }
      globalSet(handle: "footer", site: $siteHandle) {
        ... on cms_GlobalSet_Footer {
          copyright
          links {
            ... on cms_Set_Links_Links {
              link_link
              link_label
              link_external
              link_btn_type {
                label
                value
              }
            }
          }
        }
      }
      entry(id: $pageId) {
        ... on cms_Entry_Pages_PageLegal {
          id
          title
          sections {
            content
            section_title
            nav_title
          }
        }
      }
      seo: SeoMeta(page_id: $pageId) {
        og_title
        og_description
        title
        description
        og_url
        rel_canonical
        translations {
          exists
          id
          is_current
          site
          uri
        }
      }
      search: globalSet(handle: "search", site: $siteHandle) {
        ... on cms_GlobalSet_Search {
            handle
            results_text
            no_results_subtext
            no_results_text
            search_input_field
            companies_headline
            investors_headline
            suggested_search_terms {
            name
          }
          site {
            handle
            name
          }
        }
      },
      team: entries(collection: "team", limit: 3000, filter: {site: $siteHandle}) {
        to
        data {
          id
          ... on cms_Entry_Team_Team {
            __typename
            id
            title
            published
            site {
              short_locale
              locale
              handle
              name
      
            }
            slug
            preview_image {
              ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                publicURL
                extension
                childImageSharp {
                gatsbyImageData(width: 90, height: 90, formats: [AUTO, WEBP, AVIF])
                }
                absolutePath
                atime
                base
              }
              path
              }
            }
            sectors {
              ... on cms_Term_Sectors_Sectors {
              id
              title
              }
            }
            region {
              id
              ... on cms_Term_Region_Region {
              id
              title
              }
            }
          }
        }
        total
        from
        has_more_pages
      }
      portfolio_companies: entries(collection: "companies", sort: "title", filter: {site: $siteHandle}) {
        to
        data {
          id
          slug
          ... on cms_Entry_Companies_Companies {
            __typename
            id
            title
            slug
            founder_label
            founders
            company_url
            description
            status
            status_override
            company_status {
              id
              slug
              title
            }
            site {
              short_locale
              locale
              handle
              name
      
            }
            team {
              hide_on_portfolio_page
              team_member {
                id
                title
              }
            }
            region {
              ... on cms_Term_Region_Region {
                id
                title
                slug
              }
            }
            sectors {
              ... on cms_Term_Sectors_Sectors {
                id
                title
                slug
              }
            }
            logo {
              ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                publicURL
                extension
                childImageSharp {
                gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
                }
                absolutePath
                atime
                base
              }
              path
              }
              url
              path
            }
          }
        }
        total
        from
        has_more_pages
      }
    }
  }
`;
